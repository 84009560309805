<template>
  <div
    style="height: inherit"
  >
    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Manage tournament participants
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row class="mt-1 mb-1 justify-content-between">
      <b-col
        sm="12"
        md="3"
        class="d-flex justify-content-start"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search participants ..."
            type="search"
          />
          <b-input-group-append is-text>
            <feather-icon
              class="cursor-pointer"
              height="20px"
              icon="SearchIcon"
              width="20px"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        sm="12"
        md="3"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="primary"
          class="mr-2"
          @click="handleAddParticipantsClick"
        ><feather-icon icon="PlusIcon" /> Add participant
        </b-button>

        <b-button
          variant="primary"
          :disabled="tournament?.status_id >= TournamentStatusType.IN_PROGRESS"
          @click="handleShuffleClick"
        >
          <b-spinner
            v-if="isShuffling"
            small
          />
          <div v-else>
            <feather-icon icon="RefreshCwIcon" />
            Shuffle seeds
          </div>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="refParticipantsListTable"
          :fields="tableColumns"
          :items="participants"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          empty-text="No data"
          primary-key="id"
          show-empty
          class="mb-0 h-100"
          :busy.sync="loading"
          table-class="h-100"
        >
          <template #cell(seed)="data">
            {{ data.item.seed }}
          </template>

          <template #cell(name)="data">
            <span
              v-if="!isEditing(data.item.id, 'name')"
              @click="startEdit(data.item.id, 'name', data.item)"
            > {{
              data.item.participant
                ? data.item.participant.name
                : data.item.name
            }}</span>

            <span
              v-else
              class="d-flex"
            >
              <b-form-input
                v-model="editedRow.name"
                type="text"
                size="sm"
                class="mr-1"
              />

              <b-button
                id="name"
                class="btn-icon mr-1"
                variant="flat-success"
                size="sm"
                @click="saveEdit(data.item.id)"
              >
                <b-spinner
                  v-if="isUpdatingParticipant"
                  small
                />
                <feather-icon
                  v-else
                  icon="CheckIcon"
                />
              </b-button>

              <b-button
                class="btn-icon"
                variant="flat-danger"
                size="sm"
                @click="cancelEdit(data.item)"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
              <dismissible-message-box
                dismissible-message-box
                :auto-dismiss="3000"
                :show="showMessage"
                :variant="MessageType.ERROR"
                class="p-1"
                @onDismissibleMessageBoxDismissed="showMessage = false"
              >
                <feather-icon
                  class="mr-50"
                  icon="InfoIcon"
                />
                {{ message }}
              </dismissible-message-box>
            </span>

          </template>

          <template #cell(email)="data">
            <span
              v-if="!isEditing(data.item.id, 'email')"
              @click="startEdit(data.item.id, 'email', data.item)"
            > {{ data.item.participant?.email || data.item.email }}</span>

            <span
              v-else
              class="d-flex"
            >
              <b-form-input
                v-model="editedRow.email"
                type="text"
                size="sm"
                class="mr-1"
              />

              <b-button
                class="btn-icon mr-1"
                variant="flat-success"
                size="sm"
                @click="saveEdit(data.item.id)"
              >
                <b-spinner
                  v-if="isUpdatingParticipant"
                  small
                />
                <feather-icon
                  v-else
                  icon="CheckIcon"
                />
              </b-button>

              <b-button
                class="btn-icon"
                variant="flat-danger"
                size="sm"
                @click.stop="cancelEdit(data.item)"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
            </span>
          </template>

          <template #cell(status)="data">
            {{ participantStatus(data.item.status) }}
          </template>

          <template #cell(actions)="data">
            <b-button
              v-if="data.item.status === TournamentParticipantStatusType.INVITED"
              class="btn-icon mr-1"
              variant="flat-secondary"
              size="sm"
              @click.stop="handleResendInvitation(data.item)"
            >
              <b-spinner
                v-if="isSendingInvitation && actionRowId === data.item.id"
                small
              />
              <feather-icon
                v-else
                icon="SendIcon"
              />
            </b-button>

            <b-button
              class="btn-icon"
              variant="flat-danger"
              size="sm"
              @click.stop="handleRemoveParticipantClick(data.item)"
            >
              <b-spinner
                v-if="isRemovingParticipant && actionRowId === data.item.id"
                small
              />
              <feather-icon
                v-else
                icon="XCircleIcon"
              />
            </b-button>

          </template>

          <template #cell(info)="data">
            <b-button
              v-if="data.item.participant"
              class="btn-icon"
              variant="flat-info"
              size="sm"
              @click.stop="handleProfileClick(data.item)"
            >
              <feather-icon
                icon="InfoIcon"
              />
            </b-button>

          </template>

          <template #cell(registration_action)="data">
            <div>
              <b-button
                v-if="data.item.status !== TournamentParticipantStatusType.APPROVED"
                class="btn-icon"
                variant="flat-success"
                size="sm"
                @click.stop="approveRegistration(data.item.id)"
              >
                <feather-icon
                  icon="CheckIcon"
                />
              </b-button>

              <b-button
                v-if="data.item.status !== TournamentParticipantStatusType.REJECTED"
                class="btn-icon ml-1"
                variant="flat-danger"
                size="sm"
                @click.stop="declineRegistration(data.item.id)"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center">
              <b-spinner class="align-middle mr-2" />
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- add participant modal -->
    <organizer-tournament-participant-modal
      :participant-type="tournament?.participant_type_id"
      :players-per-team="tournament?.players_per_team"
      @onParticipantsAdded="fetchTournament"
    />
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import { TournamentParticipantStatusType } from '@/constants/TournamentParticipantStatusType'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import OrganizerTournamentParticipantModal from '@/views/dashboard/OrganizerTournamentParticipantModal.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import TournamentStatusType from '@/constants/TournamentStatusType'

export default {
  components: {
    DismissibleMessageBox,
    OrganizerTournamentParticipantModal,
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BSpinner,
  },
  mixins: [
    breadcrumbManager,
  ],
  data() {
    return {
      // import types
      TournamentParticipantStatusType,
      TournamentStatusType,
      MessageType,

      // local variables
      editIndex: null,
      editField: null,
      editedRow: {
        id: null,
        name: null,
        email: null,
      },
      selectedParticipant: {},
      isCancelingRegistration: false,
      isUpdatingParticipant: false,
      loading: false,
      participants: [],
      searchQuery: '',
      perfectScrollbarSettings: {
        suppressScrollX: true,
      },
      isRemovingParticipant: false,
      tournament: null,
      tableColumns: [
        {
          key: 'seed',
          label: 'seed',
          sortable: true,
          thStyle: { width: '5%' },
        },
        {
          key: 'name',
          sortable: true,
          label: 'Name',
        },
        {
          key: 'email', sortable: true, label: 'Email',
        },
        {
          key: 'status',
          sortable: true,
          label: 'Status',
          thStyle: { width: '10%' },
        },
        {
          key: 'info',
          sortable: false,
          thStyle: { width: '10%' },
          label: 'Profile',
        },
        {
          key: 'actions',
          sortable: false,
          thStyle: { width: '10%' },
          label: 'Actions',
          thClass: 'text-right',
          tdClass: 'text-right',

        },
      ],
      sortBy: 'seed',
      isSortDirDesc: false,
      isShuffling: false,
      isSendingInvitation: false,
      actionRowId: null,
      showMessage: false,
      message: null,
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  watch: {
    async searchQuery(searchValue) {
      if (!searchValue) {
        await this.fetchTournament()
      }

      this.participants = this.tournament?.participants.filter(
        participant => {
          if (!participant.participant) {
            return participant.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          }
          return participant.participant.name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        },
      )
    },

  },
  async created() {
    await this.fetchTournament()
  },
  methods: {
    startEdit(index, field, item) {
      // if  tournament is in progress, do not allow editing
      if (this.tournament?.status_id >= TournamentStatusType.IN_PROGRESS) {
        return
      }

      this.editIndex = index
      this.editField = field
      this.editedRow = JSON.parse(JSON.stringify(item))
    },
    async saveEdit(index) {
      if (this.editedRow.id !== index) return

      this.isUpdatingParticipant = true

      const { errors } = await this.$api.tournament.updateParticipant(
        this.editedRow.id,
        this.editedRow.name,
        this.editedRow.email,
      )

      this.isUpdatingParticipant = false

      this.editIndex = null
      if (!errors) {
        this.participants = this.participants.map(participant => {
          if (participant.id === index) {
            return this.editedRow
          }
          return participant
        })
      } else {
        this.showMessage = true
        this.message = 'Error updating participant data!'
      }
    },
    cancelEdit(item) {
      this.editIndex = null
      this.editedRow = item
    },
    isEditing(index, field) {
      return this.editIndex === index && this.editField === field
    },
    async handleResendInvitation(participant) {
      this.actionRowId = participant.id
      this.isSendingInvitation = true

      await this.$api.organizer.resendTournamentInvite(participant.id)

      this.isSendingInvitation = false
    },
    handleRemoveParticipantClick(participant) {
      this.$swal({
        title: `Remove ${participant?.participant
          ? participant.participant.name
          : participant.name}?`,
        text: `Are you sure you want to remove ${participant?.participant
          ? participant.participant.name
          : participant.name} from tournament?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Close',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.actionRowId = participant.id
            const { success } = await this.$api.tournament.deleteParticipant(participant.id)

            if (success) {
              // remove participant from list
              const index = this.participants.findIndex(
                p => p.id === participant.id,
              )

              if (index > -1) {
                this.participants.splice(index, 1)
              }
            }
          }
        })
    },
    handleAddParticipantsClick() {
      this.$bvModal.show('organizer-tournament-participant-modal')
    },
    async handleShuffleClick() {
      this.isShuffling = true
      await this.$api.tournament.shuffleParticipantSeeds(this.tournament.id)
      this.isShuffling = false

      await this.fetchTournament()
    },

    handleProfileClick(participant) {
      if (!participant.participant_id) {
        return
      }

      const resolvedRoute = this.$router.resolve({
        name:
            participant.participant_type === TournamentParticipantType.TEAM
              ? 'team-profile'
              : 'player-profile',
        params: { id: participant?.participant.id },
      })

      window.open(resolvedRoute.href, '_blank')
    },
    // eslint-disable-next-line consistent-return
    participantStatus(status) {
      // eslint-disable-next-line default-case
      switch (status) {
        case TournamentParticipantStatusType.INVITED:
          return 'invited'
        case TournamentParticipantStatusType.PENDING_APPROVAL:
          return 'pending approval'
        case TournamentParticipantStatusType.REJECTED:
          return 'rejected'
        case TournamentParticipantStatusType.APPROVED:
          return 'approved'
      }
    },
    async approveRegistration(id) {
      await this.$api.tournament.saveRegistration({
        tournament_participant_id: id,
        is_confirmed: true,
      })

      await this.fetchTournament()
    },
    async declineRegistration(id) {
      await this.$api.tournament.saveRegistration({
        tournament_participant_id: id,
        is_confirmed: false,
      })

      await this.fetchTournament()
    },
    async fetchTournament() {
      this.loading = true
      // fetch tournament
      const { data } = await this.$api.tournament.fetchMyTournamentById(
        Number(this.$route.params.id),
        Number(this.$route.params.tournamentId),
      )
      this.loading = false

      this.tournament = data

      this.participants = data.participants

      // add registration actions if registration is enabled
      // if (this.tournament?.enable_registration === true) {
      this.tableColumns.splice(this.tableColumns.length - 1, 0, {
        key: 'registration_action',
        sortable: false,
        thStyle: { width: '15%' },
        thClass: 'text-right',
        tdClass: 'text-right',
        label: 'Confirm participation',
      })
      // }
    },
  },
}
</script>

<style lang="scss">
.scroll-area {
  height: calc(100% - 115px);
}
</style>
