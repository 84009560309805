var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Manage tournament participants ")])])], 1)], 1), _c('b-row', {
    staticClass: "mt-1 mb-1 justify-content-between"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-start",
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search participants ...",
      "type": "search"
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "height": "20px",
      "icon": "SearchIcon",
      "width": "20px"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleAddParticipantsClick
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Add participant ")], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": ((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) >= _vm.TournamentStatusType.IN_PROGRESS
    },
    on: {
      "click": _vm.handleShuffleClick
    }
  }, [_vm.isShuffling ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('div', [_c('feather-icon', {
    attrs: {
      "icon": "RefreshCwIcon"
    }
  }), _vm._v(" Shuffle seeds ")], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-table', {
    ref: "refParticipantsListTable",
    staticClass: "mb-0 h-100",
    attrs: {
      "fields": _vm.tableColumns,
      "items": _vm.participants,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.isSortDirDesc,
      "empty-text": "No data",
      "primary-key": "id",
      "show-empty": "",
      "busy": _vm.loading,
      "table-class": "h-100"
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:busy": function updateBusy($event) {
        _vm.loading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(seed)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.seed) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function fn(data) {
        return [!_vm.isEditing(data.item.id, 'name') ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.startEdit(data.item.id, 'name', data.item);
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.participant ? data.item.participant.name : data.item.name))]) : _c('span', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          staticClass: "mr-1",
          attrs: {
            "type": "text",
            "size": "sm"
          },
          model: {
            value: _vm.editedRow.name,
            callback: function callback($$v) {
              _vm.$set(_vm.editedRow, "name", $$v);
            },
            expression: "editedRow.name"
          }
        }), _c('b-button', {
          staticClass: "btn-icon mr-1",
          attrs: {
            "id": "name",
            "variant": "flat-success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.saveEdit(data.item.id);
            }
          }
        }, [_vm.isUpdatingParticipant ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "CheckIcon"
          }
        })], 1), _c('b-button', {
          staticClass: "btn-icon",
          attrs: {
            "variant": "flat-danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.cancelEdit(data.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1), _c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "dismissible-message-box": "",
            "auto-dismiss": 3000,
            "show": _vm.showMessage,
            "variant": _vm.MessageType.ERROR
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showMessage = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1)];
      }
    }, {
      key: "cell(email)",
      fn: function fn(data) {
        var _data$item$participan;
        return [!_vm.isEditing(data.item.id, 'email') ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.startEdit(data.item.id, 'email', data.item);
            }
          }
        }, [_vm._v(" " + _vm._s(((_data$item$participan = data.item.participant) === null || _data$item$participan === void 0 ? void 0 : _data$item$participan.email) || data.item.email))]) : _c('span', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          staticClass: "mr-1",
          attrs: {
            "type": "text",
            "size": "sm"
          },
          model: {
            value: _vm.editedRow.email,
            callback: function callback($$v) {
              _vm.$set(_vm.editedRow, "email", $$v);
            },
            expression: "editedRow.email"
          }
        }), _c('b-button', {
          staticClass: "btn-icon mr-1",
          attrs: {
            "variant": "flat-success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.saveEdit(data.item.id);
            }
          }
        }, [_vm.isUpdatingParticipant ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "CheckIcon"
          }
        })], 1), _c('b-button', {
          staticClass: "btn-icon",
          attrs: {
            "variant": "flat-danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.cancelEdit(data.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.participantStatus(data.item.status)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [data.item.status === _vm.TournamentParticipantStatusType.INVITED ? _c('b-button', {
          staticClass: "btn-icon mr-1",
          attrs: {
            "variant": "flat-secondary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleResendInvitation(data.item);
            }
          }
        }, [_vm.isSendingInvitation && _vm.actionRowId === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "SendIcon"
          }
        })], 1) : _vm._e(), _c('b-button', {
          staticClass: "btn-icon",
          attrs: {
            "variant": "flat-danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleRemoveParticipantClick(data.item);
            }
          }
        }, [_vm.isRemovingParticipant && _vm.actionRowId === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(info)",
      fn: function fn(data) {
        return [data.item.participant ? _c('b-button', {
          staticClass: "btn-icon",
          attrs: {
            "variant": "flat-info",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleProfileClick(data.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(registration_action)",
      fn: function fn(data) {
        return [_c('div', [data.item.status !== _vm.TournamentParticipantStatusType.APPROVED ? _c('b-button', {
          staticClass: "btn-icon",
          attrs: {
            "variant": "flat-success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.approveRegistration(data.item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckIcon"
          }
        })], 1) : _vm._e(), data.item.status !== _vm.TournamentParticipantStatusType.REJECTED ? _c('b-button', {
          staticClass: "btn-icon ml-1",
          attrs: {
            "variant": "flat-danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.declineRegistration(data.item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-2"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('organizer-tournament-participant-modal', {
    attrs: {
      "participant-type": (_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.participant_type_id,
      "players-per-team": (_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : _vm$tournament3.players_per_team
    },
    on: {
      "onParticipantsAdded": _vm.fetchTournament
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }